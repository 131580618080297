import React from 'react';
// import { useDispatch } from 'react-redux';
// import {useNavigate} from 'react-router-dom';
// import { showLoginFormAction } from '../actions/appActions';
// import useStoreState from '../hooks/useStoreState';
import { Box, Typography } from '@mui/material';
import bgImg1x from '../assets/images/grunge-gray-concrete-textured-background.jpg';
import logo from '../assets/images/UZUZD-yellow-1row-rounded.png';

const Transformation = (props) => {
	// const dispatch = useDispatch();
	// const navigate = useNavigate();
	// const auth = useStoreState('auth');

	// const showLoginForm = React.useCallback(() => {
	// 	props.hide?.apply();
	// 	dispatch(showLoginFormAction());
	// }, [dispatch, props.hide]);

	return (
		<Box
			sx={{
				position: 'absolute', top: '0', left: '0',
				display: 'flex',
				width: '100%',
				height: '100%',
				justifyContent: { md: 'center' },
				alignItems: { xs: 'flex-start', md: 'center'},
				backgroundImage: `url(${bgImg1x})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					justifyContent: { xs: 'flex-start', md: 'center' },
					alignItems: { xs: 'flex-start', md: 'center'},
					pt: { xs: 8, sm: 16, md: 0 },
					gap: { md: 8},
					position: 'relative',
					flexDirection: { xs: 'column', md: 'row' },
				}}
			>
				<Box
					sx={{
						p: { xs: 4, sm: 8 },
						position: 'relative',
						flexShrink: 0,
						display: 'flex',
						
					}}
				>
					<Box
							component="img"
							src={logo}
							sx={{
								// maxWidth: {xs: '100%', sm: '260px'},
								// maxHeight: {xs: '100%', sm: '260px'}
								maxWidth: {xs: '160px', md: '260px'},
								maxHeight: {xs: '160px', md: '260px'}
							}}
						/>
				</Box>
				<Box
					sx={{
						maxWidth: {md: '460px'},
						px: {xs: 4, sm: 8},
						pt: {xs: 4, sm: 8},
					}}
				>
					<Typography
						variant="h1"
						sx={theme=>({
							color: theme.palette.white.main,
							fontWeight: 600,
							lineHeight: '2.875rem',
							mb: { xs: 2, sm: 4, md: 6},
							[ theme.breakpoints.down('lg') ]: {
								fontSize: '2.3rem',
								lineHeight: '2.875rem',
							},
							[ theme.breakpoints.down('md') ]: {
								fontSize: '2.1rem',
								lineHeight: '2.5rem',
							},
							[ theme.breakpoints.down('sm') ]: {
								fontSize: '1.8rem',
								lineHeight: '2rem',
							}
						})}
					>A UZUZD átalakul.</Typography>
					<Typography
						variant="h2"
						sx={theme=>({
							color: theme.palette.white.main,
							fontWeight: 400,
							[ theme.breakpoints.down('sm') ]: {
								fontSize: '1.3rem',
								lineHeight: '1.5rem',
							},
							marginBottom: '16px'
						})}
					>
						Hamarosan újdonságokkal jelentkezünk.
					</Typography>
					
					<Typography
						variant="h3"
						sx={theme=>({
							color: theme.palette.white.main,
							fontWeight: 400,
							[ theme.breakpoints.down('sm') ]: {
								fontSize: '1.3rem',
								lineHeight: '1.5rem',
							},
							marginBottom: { xs: 8, sm: 16 }
						})}
					>
							Ha kérdésed van, <Box sx={theme =>({display: 'inline', color: theme.palette.white.main })} key={1} component="a" href={`mailto:hello@uzuzd.com`} target="_blank" rel="noreferrer">írj nekünk.</Box>
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
export default Transformation;